import React from 'react';
import './ThemeDisplay.css';

function ThemeDisplay({ theme, isDarkMode }) {
  return (
    <div className={`theme-container ${isDarkMode ? 'dark-mode' : ''}`}>
      <div className={`theme-header ${isDarkMode ? 'dark-mode' : ''}`}>TODAY’S THEME</div>
      <div className={`theme-content ${isDarkMode ? 'dark-mode' : ''}`}>{theme}</div>
    </div>
  );
}

export default ThemeDisplay;
