import './themeToggle.css';

function ThemeToggle({ onToggle, isDarkMode }) {
    return (
      <button className={`mode ${isDarkMode ? 'dark' : 'light'}`} onClick={onToggle}>
        {isDarkMode ? '🌞 Light Mode' : '🌙 Dark Mode'}
      </button>
    );
  }

export default ThemeToggle;