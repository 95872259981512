// MovieQuiz.js
import React, { useState } from 'react';
import StyledInput from './StyledInput';
import HintButton from './HintButton';
import './MovieQuiz.css'; // Ensure you import the CSS file
import RevealModal from './RevealModal';

function MovieQuiz({ level, movie, onGuess, onRevealHint, questionStatus, isDarkMode }) {
  const [guess, setGuess] = useState('');
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);

  const hints = {
    year: movie.year,
    genre: movie.genre,
    director: movie.director,
    mainActor: movie.mainActor,
  };

  const handleReveal = async () => {
    setModalOpen(true);
    setGuess('');
  };

  const handleGuess = async () => {
    if (!!guess){
      setLoading(true);
      await onGuess(guess, level);
      setLoading(false);
      setGuess('');
    }
  };

  const renderMistakes = () => {
    const dots = [];
    for (let i = 0; i < 4; i++) {
      dots.push(
        <span key={i} className={`dot ${i < questionStatus.mistakes ? 'used' : ''}`}></span>
      );
    }
    return dots;
  };

  const isAnswered = questionStatus.status !== 'unanswered';

  return (
    <div className={`movie-quiz ${level} ${isDarkMode? 'dark-mode': ''} ${isAnswered ? 'done' : ''}`}>
      <div className="card-header">
        {(
          <div className="reveal">
            {questionStatus.status === "unanswered" ? 
            (<button className={`revealButton ${isDarkMode? 'dark-mode': ''}`} onClick={handleReveal}>🔍</button>) : 
            (null)}
          </div>
        )}
        {(
          <div className="mistakes">
            {questionStatus.status === "unanswered" ? (
              <>Guesses: {renderMistakes(questionStatus.mistakes)}</>
            ) : (
              questionStatus.status === "correct" ? "✅" : (
              questionStatus.status === "incorrect" ? "❌" : (
                null
              )
            )
            )}
          </div>
        )}
        </div>
      <div className='movie-content'>
        <div className="emoji">{movie.emojis}</div>
        <div className="question-container">
            {isAnswered ? (
            <p className="movie-title">{movie.title}</p>
            ) : loading? (
                <div className="loading-spinner"></div>
            ) :(<div className="input-container">
                <StyledInput
                value={guess}
                onChange={(e) => setGuess(e.target.value)}
                onKeyDown={(e) => e.key === 'Enter' && handleGuess()}
                placeholder={questionStatus.placeholder}
                isDarkMode={isDarkMode}
                />
                <button onClick={handleGuess} className={`arrow-button ${isDarkMode ? 'dark' : 'light'}`}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M13 11h-8v2h8v3l4-4-4-4z"/>
                  </svg>
                </button>
            </div>
            )}
        </div>
      </div>
      {!isAnswered && (
        <HintButton level={level} onRevealHint={onRevealHint} hints={hints} isDarkMode={isDarkMode} />
      )}
      <RevealModal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        isDarkMode={isDarkMode}
        level={level}
        onGuess={onGuess}
        questionStatus={questionStatus}
      />
    </div>
  );
}

export default MovieQuiz;
