import React from 'react';
import './Modal.css';

const HowToPlayModal = ({ onClose }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content left">
        <button className="close-button" onClick={onClose}>×</button>
        <h2>How to Play Mojie</h2>
        <p>Solve each emoji based clue to reveal the title of a movie!</p> 
        <p>Use the theme of the day to aid your guesses.</p>
        <ul>
          <li>Type your answer and enter it in each box.</li>
          <li>If you are stuck, the 🗓️🎭🎬🌟 buttons below each clue are hints you may reveal (that add a 💡 penalty to your result). </li>
          <li>Click 🗓️ to reveal the year of release, 🎭 to reveal the genre, 🎬 to reveal the director(s), and 🌟 to reveal the main actor of the film.</li>
          <li>You are allowed 4 guesses ⚪️ per movie.</li>
          <li>If you use up all of your guesses, the answer will be revealed and you will receive a ❌ penalty in your result.</li>
          <li>If you click 🔍, this will reveal the answer without any guess attempts, and you will receive a ❌ penalty in your result.</li>
          <li>If you answer a movie correctly before your guess attempts are used, you will earn a ✅ in your result.</li>
          <li>You may only view and share your results once all 3 movies have been solved!</li>
        </ul>
        <h2>Clue Examples</h2>
        <ul>
          <li>Theme: “Bon Appétit”, Clue: 👨🏼‍🍳🦱🐀 🍝, Answer: “Ratatouille”</li>
          <li>Theme: “Fruit Salad”, Clue: 🕛🕒🕓🍊, Answer: "Clockwork Orange"</li>
        </ul>
        <p>The movies are ordered by relative level of difficulty:</p>
        <p>Easy 🟩 - Medium 🟦 - Hard 🟪</p>
        <p>Each movie has exactly one correct answer. Good luck cinephiles!</p>
        <small>(Note: Our grading system will take into account small typos and discrepancies to the original movie title, but do your best to type in the answer as accurately as possible! Currently this quiz is only supported on Chrome and Safari.)</small>
      </div>
    </div>
  );
};

export default HowToPlayModal;
