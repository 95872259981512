/* eslint-disable no-unused-vars */
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid'; // For generating unique identifiers
import ResultsModal from './ResultsModal';
import ThemeDisplay from './ThemeDisplay';
import MovieQuiz from './MovieQuiz';
import Toolbar from './Toolbar';
import './App.css';

function App() {
  const [quiz, setQuiz] = useState(null);
  const [stats, setStats] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [questionStatuses, setQuestionStatuses] = useState({
    easy: { status: 'unanswered', hints: { year: false, genre: false, director: false, mainActor: false }, mistakes: 0, graded: false, emoji: ['🟩'], placeholder: "Enter your guess here"},
    medium: { status: 'unanswered', hints: { year: false, genre: false, director: false, mainActor: false }, mistakes: 0, graded: false, emoji: ['🟦'], placeholder: "Enter your guess here"},
    hard: { status: 'unanswered', hints: { year: false, genre: false, director: false, mainActor: false }, mistakes: 0, graded: false, emoji: ['🟪'], placeholder: "Enter your guess here"},
  });
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [results, setResults] = useState('');
  const [error, setError] = useState(null);
  const [mistakesMade, setMistakesMade] = useState(0);
  const [answers, setAnswers] = useState(0);
  const [hintsUsed, setHintsUsed] = useState(0);
  const [showHowToPlay, setShowHowToPlay] = useState(false);
  const [date, setDate] = useState('');

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
  };
  
  let userId = Cookies.get('userId');
  if (!userId) {
    userId = uuidv4();
    Cookies.set('userId', userId, { expires: 365 }); // Set cookie to expire in 1 year
  }

  const areAllAnswersDone = () => {
    return Object.values(questionStatuses).every(status => status.status !== 'unanswered');
  };

  useEffect(() => {
    document.body.className = isDarkMode ? 'dark-mode' : 'light-mode';
  }, [isDarkMode]);

    useEffect(() => {
      async function fetchData() {
        if (areAllAnswersDone()) {
          const resultsString = await getResultsEmojiString();
          await new Promise(resolve => setTimeout(resolve, 200));
          setResults(resultsString);
          await new Promise(resolve => setTimeout(resolve, 200));
          handleShareResults();
        }
      }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },  [questionStatuses]);


  useEffect(() => {
    const apiEndpoint = process.env.REACT_APP_API_ENDPOINT || 'https://us-central1-the-emoji-quiz.cloudfunctions.net/api';

    fetch(`${apiEndpoint}/daily-quiz?userId=${userId}`)
      .then(async response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        setQuiz(data);
        setError(null);
        setDate(data.date)
      })
      .catch(error => {
        console.error('Error fetching quiz data:', error);
        setError(error);
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await axios.get(`https://us-central1-the-emoji-quiz.cloudfunctions.net/api/stats?userId=${userId}`);
        setStats(response.data);
        setShowHowToPlay(response?.data?.gamesPlayed === 0)
        setIsDarkMode(response?.data?.darkMode)
      } catch (error) {
        console.error(error.message);
      }
    };
    fetchStats();

    // Fetch user progress
    const fetchProgress = async () => {
      try {
        const response = await axios.get(`https://us-central1-the-emoji-quiz.cloudfunctions.net/api/progress?userId=${userId}`);
        await new Promise(resolve => setTimeout(resolve, 200));
        if (response.data) {
          setQuestionStatuses(response.data.progress);
        }
      } catch (error) {
        console.error(error.message);
      }
    };
    fetchProgress();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateStats = async (gamesPlayed, gamesWon, hintsUsed, mistakesMade, darkMode) => {
    const userId = Cookies.get('userId');
    try {
      const response = await axios.post('https://us-central1-the-emoji-quiz.cloudfunctions.net/api/update-stats', {
        userId,
        gamesPlayed,
        gamesWon,
        hintsUsed,
        mistakesMade,
        darkMode,
      });
      setStats(response.data);
    } catch (error) {
      console.error(error.message);
    }
  };

  const updateProgress = async (progress, date) => {
    try {
      await axios.post('https://us-central1-the-emoji-quiz.cloudfunctions.net/api/update-progress', { userId, progress, date });
      await new Promise(resolve => setTimeout(resolve, 200));
      setQuestionStatuses(progress);
    } catch (error) {
      console.error(error.message);
    }
  };

  const updateFeedback = async (likes, dislikes) => {
    try {
      await axios.post('https://us-central1-the-emoji-quiz.cloudfunctions.net/api/feedback', { likes, dislikes });
      await new Promise(resolve => setTimeout(resolve, 200));
    } catch (error) {
      console.error(error.message);
    }
  };

  const checkAnswer = async (guess, level) => {
    const updatedStatuses = { ...questionStatuses };

    // Reveal question 
    if (updatedStatuses[level].status === 'revealed') {
      updatedStatuses[level].status = 'incorrect';
      updatedStatuses[level].emoji = [...updatedStatuses[level].emoji, '❌']
      await new Promise(resolve => setTimeout(resolve, 200));
    } 

    // Actually Answer Question
    else {
    const levenshteinAnswer = checkLevenshteinAnswer(guess, level, 3);
    if (levenshteinAnswer && updatedStatuses[level].status === 'unanswered') {
      setAnswers(answers + 1);
      updatedStatuses[level].status = 'correct';
      updatedStatuses[level].emoji = [...updatedStatuses[level].emoji, '✅']
      await new Promise(resolve => setTimeout(resolve, 200));
    } else {
      if (checkLevenshteinAnswer(guess, level, 6)) {
        updatedStatuses[level].placeholder = "So close! Try again!"
      } else {
        updatedStatuses[level].placeholder = "Try again"
      }
      updatedStatuses[level].mistakes = updatedStatuses[level].mistakes + 1
      updatedStatuses[level].emoji = [...updatedStatuses[level].emoji, '⚪️']
      await new Promise(resolve => setTimeout(resolve, 200));
        if (questionStatuses[level].mistakes >= 4) {
          updatedStatuses[level].status = 'incorrect';
          updatedStatuses[level].emoji = [...updatedStatuses[level].emoji, '❌']
          await new Promise(resolve => setTimeout(resolve, 200));
        }
      }
      }
      
      setQuestionStatuses(updatedStatuses);
      await updateProgress(updatedStatuses, quiz?.date || date);
  };

  // deprecated

  // const checkAnswerApi = async (guess, level) => {
  //   const apiEndpoint = process.env.REACT_APP_API_ENDPOINT || 'https://us-central1-my-emoji-quiz.cloudfunctions.net/api';
  //   const correctAnswer = quiz[level].title;
  //   await fetch(`${apiEndpoint}/check-guess`, {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify({ guess, correctAnswer }),
  //     mode: 'cors'
  //   })
  //     .then(async response => response.json())
  //     .then(async data => {
  //       const updatedStatuses = { ...questionStatuses };

  //       if (data.correct && updatedStatuses[level].status === 'unanswered') {
  //         setAnswers(answers + 1);
  //         updatedStatuses[level].status = 'correct';
  //         await updateProgress(updatedStatuses, (prevActions => ({...prevActions, [level]:`${prevActions[level]}✅`})), quiz.date);
  //         await new Promise(resolve => setTimeout(resolve, 200));
  //       } else {
  //         updatedStatuses[level].mistakes = updatedStatuses[level].mistakes + 1
  //         await updateProgress(updatedStatuses, (prevActions => ({...prevActions, [level]:`${prevActions[level]}⚪️`})), quiz.date);
  //         await new Promise(resolve => setTimeout(resolve, 200));
  //           if (questionStatuses[level].mistakes >= 4) {
  //             updatedStatuses[level].status = 'incorrect';
  //             await updateProgress(updatedStatuses, (prevActions => ({...prevActions, [level]:`${prevActions[level]}❌`})), quiz.date);
  //             await new Promise(resolve => setTimeout(resolve, 200));
  //           }
  //       }
  //       setQuestionStatuses(updatedStatuses);
  //       console.log(updatedStatuses)
  //     });
  // };

  // List of correct answers and acceptable variations
  
  const levenshtein = require("fast-levenshtein");
  // Function to check if the guess is correct
  const checkLevenshteinAnswer = (guess, level, threshold) => {
    const userInputLower = guess?.toLowerCase();
    const correctTitles = quiz[level].answers || null;
    const correctTitlesLower =correctTitles?.map((title) => title.toLowerCase()) || [];
    // Check for exact match
    if (correctTitlesLower?.includes(userInputLower)) {
      return true;
    }
    // Check for close matches using Levenshtein Distance
    for (const title of correctTitlesLower) {
      const distance = levenshtein.get(userInputLower, title);
      if (distance <= threshold) {
        return true;
      }
    }
    return false;
  };

  const revealHint = (level, hintType) => {
    const updatedStatuses = { ...questionStatuses };
    if (updatedStatuses[level] && updatedStatuses[level].hints) {
      updatedStatuses[level].hints[hintType] = true;
      updatedStatuses[level].emoji = [...updatedStatuses[level].emoji, '💡']
      setQuestionStatuses(updatedStatuses);
      updateProgress(questionStatuses, quiz?.date || date);
    }
  };

  const getResultsEmojiString = async () => {
    return `${questionStatuses["easy"].emoji.join('')}\n${questionStatuses["medium"].emoji.join('')}\n${questionStatuses["hard"].emoji.join('')}`;
  };

  const handleShareResults = async () => {
    const mistakes = questionStatuses["easy"].mistakes +questionStatuses["medium"].mistakes + questionStatuses["hard"].mistakes
    setMistakesMade(mistakes)

    const gamesPlayed = 1; // Assuming one game played per session
    const gamesWon = (answers === 3 ? 1 : 0)
    
    const hintsUsed = (questionStatuses["easy"].hints.year ? 1 : 0) +
                      (questionStatuses["easy"].hints.genre ? 1 : 0) +
                      (questionStatuses["easy"].hints.director ? 1 : 0) +
                      (questionStatuses["easy"].hints.mainActor ? 1 : 0) +
                      (questionStatuses["medium"].hints.year ? 1 : 0) +
                      (questionStatuses["medium"].hints.genre ? 1 : 0) +
                      (questionStatuses["medium"].hints.director ? 1 : 0) +
                      (questionStatuses["medium"].hints.mainActor ? 1 : 0) +
                      (questionStatuses["hard"].hints.year ? 1 : 0) +
                      (questionStatuses["hard"].hints.genre ? 1 : 0) +
                      (questionStatuses["hard"].hints.director ? 1 : 0) +
                      (questionStatuses["hard"].hints.mainActor ? 1 : 0);
    setHintsUsed(hintsUsed);

    // Check if the quiz has already been graded
    if (questionStatuses.easy.graded || questionStatuses.medium.graded || questionStatuses.hard.graded) {
      await updateProgress(questionStatuses, quiz?.date || date);
      return;
    }

    questionStatuses["easy"].graded = true;
    questionStatuses["medium"].graded = true;
    questionStatuses["hard"].graded = true;

    await updateStats(gamesPlayed, gamesWon, hintsUsed, mistakes, isDarkMode);
    await updateProgress(questionStatuses, quiz?.date || date);
  };

  const shareResults = `m🙂jie\n"${quiz?.theme}"\n${results}\nmojiequiz.com`
  const copyResultsToClipboard = () => {
    navigator.clipboard.writeText(shareResults)
      .then(() => {
      })
      .catch(err => {
        console.error('Failed to copy results:', err);
      });
  };
  
  return (
    <div className={isDarkMode ? 'dark-mode' : 'light-mode'}>
      <div className='App'>
          <>
            <div>
              <Toolbar isDarkMode={isDarkMode} onToggle={toggleTheme} stats={stats} showHowToPlay={showHowToPlay} />
            </div>
            <br />
            <header className="App-header">
              <h1>m🙂jie</h1>
              {error && <p>Error: {error.message}</p>}
              {quiz ? (
                <>
                  <ThemeDisplay theme={quiz.theme} isDarkMode={isDarkMode} />
                  <div className="movie-levels">
                    {['easy', 'medium', 'hard'].map(level => (
                      <MovieQuiz
                        key={level}
                        level={level}
                        movie={quiz[level]}
                        onGuess={checkAnswer}
                        onRevealHint={revealHint}
                        questionStatus={questionStatuses[level]}
                        isDarkMode={isDarkMode}
                      />
                    ))}
                  </div>
                </>
              ) : (
                <p>Loading quiz...</p>
              )}
            </header>
            <button className="view-results-button" onClick={() => setIsModalOpen(true)} hidden={!areAllAnswersDone()}>View Results</button>
            <ResultsModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                results={results}
                onCopy={copyResultsToClipboard}
                theme={quiz? quiz.theme: null}
                date={quiz? quiz.date: date}
                isDarkMode={isDarkMode}
                mistakes={mistakesMade}
                answers={answers}
                hints={hintsUsed}
                updateFeedback={updateFeedback}
              />
          </>
      </div>
    </div>
  );
}

export default App;
